import React from 'react'
import { graphql } from 'gatsby'

import Carousel from '../../../components/carousel'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'MSR Sound Rating Carpet' },
    { img: data.i2, caption: 'MSR Sound Rating Hardwood' },
  ];

  return (
    <Layout>
      <SEO title="System Components: EPICORE MSR Floor System: STC Rating" keywords={[]}/>
      <div className="container gallery-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Components: EPICORE MSR Floor System</h2>
        <h1 className='title title--xxxl color-primary'>STC Rating</h1>
        <div className='carousel-container carousel-container--lg'>
          <Carousel
            content={images}
            carWidth={846}
            carHeight={476}
          />
        </div>
        <div>
          <p>A major advantage of the Epicore MSR Composite Floor System is its
          excellent Sound Ratings.  It would not be prudent to say any floor system is
          “soundproof”, however the Epicore MSR System provides you with one of the
          quietest floor systems available.  You really can “feel” the quality when
          you are in an Epicore MSR building when it comes to noise reduction.</p>

          <p>Infinity Structures and Epic Metals have engaged multiple independent laboratories
          and Acoustical Consultants to perform STC and IIC tests on our Epicore MSR Composite
          Floor System. It has been tested with various floor coverings and underlayments
          on top of the slab, as well as various ceiling treatments underneath the slab.</p>

          <p>A few of the tested assemblies are shown above. Please contact Infinity Structures
          for a copy of our <span className='type-bold type-italic'>Epicore MSR Sound Test
          Analysis</span> which outlines all the various tests and their STC and IIC results.</p>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-components/epicore-msr-floor-system/stc-rating/gallery/01-43048.png" }) {
            ...GalleryCarouselTechnical1_77ImageFragment },
        i2: file(relativePath: { eq: "system-components/epicore-msr-floor-system/stc-rating/gallery/02-43047.png" }) {
            ...GalleryCarouselTechnical1_77ImageFragment },
    }
`
